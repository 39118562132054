<template>
  <router-view />
</template>
<script>
import { mapActions } from 'vuex'
import { registerModelModuleContext, MODEL_MODULE_NAMESPACE } from '@/modules/modelManagement'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ModelManagementIndex',
  setup () {
    registerModelModuleContext()
  },
  mounted () {
    this.setCurrentAskingModuleBelongsTo(MODEL_MODULE_NAMESPACE)
  },
  methods: {
    ...mapActions('dataSource', ['setCurrentAskingModuleBelongsTo'])
  }
})
</script>
